import React, { useEffect, useState } from "react";
import axios from "axios";
import { getCashfree } from "./utils"; // Ensure this is correctly configured for production
import "./paypal.css";
import cashfreeimg from "../Cashfreepayment/cashfree.png";
import { useParams } from "react-router-dom";
// import { load } from '@cashfreepayments/cashfree-js';

const Cashfree = () => {
    const params = useParams();
    const isSessionId = params.sessionid;
    
    const [loading, setLoading] = useState(false);
    const [sessionId, setSessionId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');
    const [phone, setPhone] = useState('');
    const version = "2025-01-01";

    const getSessionId = async () => {
        if (!name || !email || !amount || !phone) {
            alert("Please fill in all fields.");
            return null;
        }
        
        setLoading(true);
        try {
            const res = await axios.post(`https://sae-backend.vercel.app/api/payment`, {
                version,
                name,
                email,
                amount,
                phone
            });

            console.log(res.data);
            setLoading(false);
            return res.data.payment_session_id; // Extract session ID from response
        } catch (err) {
            setLoading(false);
            console.error("Error generating session ID:", err);
            alert("Failed to initiate payment. Please try again.");
            return null;
        }
    };

    const handlePayment = async (e) => {
        e.preventDefault(); // Prevent default form submission

        const newSessionId = await getSessionId(); // Get session ID from API
        if (!newSessionId) return; // Stop if session ID is not received
        const cashfree = await getCashfree();
        setSessionId(newSessionId); // Update session ID state

        let checkoutOptions = {
            paymentSessionId: newSessionId, // Use the latest session ID
            returnUrl: `https://saenitkurukshetra.com/cashfree/test/payment`,
        };

        cashfree.checkout(checkoutOptions).then(function (result) {
            if (result.error) {
                alert(result.error.message);
            }
            if (result.redirect) {
                console.log("Redirection", result);
            }
        });
    };

    useEffect(async () => {
        setSessionId(isSessionId);
    }, [isSessionId]);

    return (
        <div className="main">
            <div className="center">
                <img width={300} src={cashfreeimg} alt="Cashfree" />
            </div>

            <div>
                <h2>Name</h2>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
            </div>
            <div>
                <h2>Phone</h2>
                <input type="number" value={phone} onChange={(e) => setPhone(e.target.value)} required />
            </div>
            <div>
                <h2>Email</h2>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div>
                <h2>Amount</h2>
                <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required />
            </div>
            
            <div className="card-pay px-5 py-4 mt-5">
                {!loading ? (
                    <div className="col-12 center">
                        <button className="w-100" type="submit" onClick={handlePayment}>
                            Pay Now
                        </button>
                    </div>
                ) : (
                    <div className="col-12 center">
                        <button className="w-100 text-center" type="submit" disabled>
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Processing...</span>
                            </div>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cashfree;
